import { ClientConfig } from '../configClient'
import { EventDetailsFragment } from '../graphql/fragments/eventDetails.fragment.gen'
import { dayMonthShortFormat } from './dateTimeUtils'

export function formatEventMetaText(event: EventDetailsFragment) {
  const { startTime, artists, venue } = event

  const date = new Date(startTime)
  const formattedTime = dayMonthShortFormat.format(date)

  const artistNameString = artists.map((artist) => artist.name).join(', ')

  let venueText = venue ? `at ${venue.name.trim()}` : ''

  return `${formattedTime} ${venueText}: ${artistNameString}`
}

type CreateEventLinkOptions = {
  slug: string
  clientUrl?: string
}
export function createEventLink({ slug, clientUrl }: CreateEventLinkOptions) {
  const relativePath = `/event/${slug}`

  if (!clientUrl) {
    return relativePath
  }

  return `${clientUrl}${relativePath}`
}

// TODO: refactor this into a hook to include this sales attributions logic
// https://github.com/yupty/front/blob/b1594ceb9256b7b8f5de92a48b7f0e37e6712e83/client/pages/EventDetail/EventDetail.tsx#L65
// https://github.com/yupty/front/blob/8f09b9e2a0acc41fa15875fce8b03b2dd514dc6d/client/components/EventCard/EventFooterButtons/EventFooterButtons.tsx#L50
export function createEventShareData(
  event: EventDetailsFragment,
  config: ClientConfig,
) {
  const { slug, artists, name } = event

  return {
    url: createEventLink({ slug, clientUrl: config.clientUrl }),
    title: name ?? artists.map((artist) => artist.name).join(', '),
    text: formatEventMetaText(event),
  }
}
